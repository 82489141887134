import React from 'react'
import styles from './styles.module.scss'


// Change name to input 
const index = () => {
  return (
    <div className={styles.container}>

        <div className={styles.wrapper}>
        <div className={styles.nav}>

            <div className={styles.leftLogo}>
            <img src='./littlemonster.png' alt='monster' className={styles.image}/>
        <h3>Batchforum</h3>
        </div>


<div className={styles.buttonContainer}>
    <button>Login</button>
    <button>Sign up</button>
</div>

        </div>
        </div>
      
    </div>
  )
}

export default index
