import React from 'react'
import styles from './styles.module.scss';
import HeadingNav from './headingnav/index';
import { FaArrowAltCircleRight as RightArrow } from "react-icons/fa";



const index = () => {
  return (
<div className={styles.container}>

<HeadingNav/>
<div className={styles.heroContent}>

<img src='/littlemonster.png' alt='smile now' className={styles.img}/>

<div className={styles.textContainer}>
    <h1>Discover real-world <br/> design inspiration.</h1>

    <div className={styles.textDescription}>
        <h4>Featuring over 30,000 screens and 1,000 ios, Android & Web apps- 
            <br/> new content weekly
        </h4>
    </div> 

    </div>

    <div className={styles.buttonContainer}>
        <button className={styles.button}>Discover Now</button>
        <button className={styles.button}>See our plans <span style={{marginLeft: '10px'}}> <RightArrow/> </span></button>
    </div>


</div>

</div>
  )
}

export default index
